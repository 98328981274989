import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import router from './router'
import 'tailwindcss/dist/tailwind.min.css'
import store from './store'

import {
  Sidebar,
  SidebarItem,
  IndexBar,
  IndexAnchor,
  Cell,
  CellGroup,
  Card,
  Button,
  Icon,
  SubmitBar,
  Popup,
  Calendar,
  ActionSheet,
  Tab,
  Tabs,
  List,
  Grid,
  GridItem,
  RadioGroup,
  Radio,
  Field,
  NoticeBar,
  Image as VanImage,
  Stepper,
  Picker,
  Divider,
  Lazyload
} from 'vant'

Vue.use(Lazyload)
Vue.use(Sidebar)
Vue.use(SidebarItem)
Vue.use(IndexBar)
Vue.use(IndexAnchor)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(Card)
Vue.use(Button)
Vue.use(Icon)
Vue.use(SubmitBar)
Vue.use(Popup)
Vue.use(Calendar)
Vue.use(ActionSheet)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(List)
Vue.use(Grid)
Vue.use(GridItem)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Field)
Vue.use(NoticeBar)
Vue.use(VanImage)
Vue.use(Stepper)
Vue.use(Picker)
Vue.use(Divider)

Vue.use(VueRouter)

Vue.config.productionTip = false

Vue.filter('getPrice', function(food) {
  if (!food.mprice) {
    return food.outprice
  }

  return food.mprice
})

Vue.filter('getOriginPrice', function(food) {
  if (!food.mprice) {
    return null
  }

  return food.price
})

Vue.filter('getImageUrl', function(food, big = false) {
  return `${
    location.origin
  }/dishimages${big ? '/big' : ''}/${food.code}.${window.CONFIG.imgSuffix}?time=${+new Date()}`
})

Vue.filter('getKouWei', function(food) {
  return food.specs?.map(spec => spec.kwname).join('/')
})

document.title = window.CONFIG.title

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
