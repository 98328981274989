import VueRouter from 'vue-router'

const router = new VueRouter({
  routes: [
    {
      name: 'register',
      path: '/register',
      component: () => import('./pages/register')
    },
    {
      name: 'menu',
      path: '/menu',
      component: () => import('./pages/menu')
    },
    {
      name: 'checkout',
      path: '/checkout',
      component: () => import('./pages/checkout')
    },
    {
      name: 'orderType',
      path: '/type',
      component: () => import('./pages/type')
    },
    {
      name: 'beds',
      path: '/beds',
      component: () => import('./pages/beds')
    },
    {
      name: 'orders',
      path: '/orders',
      component: () => import('./pages/orders')
    },
    {
      name: 'order',
      path: '/order',
      component: () => import('./pages/order')
    },
    {
      name: 'recharge',
      path: '/recharge',
      component: () => import('./pages/recharge')
    },
    {
      name: 'member',
      path: '/member',
      component: () => import('./pages/member')
    },
    {
      name: 'food',
      path: '/food/:code',
      component: () => import('./pages/food')
    },
    {
      name: 'receipt',
      path: '/receipt',
      component: () => import('./pages/receipt')
    },
    {
      path: '*',
      component: () => import('./pages/login')
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (location.search) {
    location.replace(`${location.origin}/${location.hash}${location.search}`)
  } else {
    next()
  }
})

export default router
