import { sum, values } from 'lodash'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const cartKeyToken = 'cart_key_v3' + window.CONFIG.appId
const getItemId = item => {
  return [item.code]
    .concat(item.specs?.map(spec => spec.id).sort() || [])
    .join('&')
}
const getCart = () => {
  try {
    return (
      JSON.parse(localStorage.getItem(cartKeyToken)) || {
        items: {}
      }
    )
  } catch {
    return {
      items: {}
    }
  }
}

const setCart = () => {
  localStorage.setItem(cartKeyToken, JSON.stringify(store.getters.cart))
}

const store = new Vuex.Store({
  state: {
    cart: getCart(),
    date: null,
    orderType: null,
    bed: null,
    afterCheckout: false
  },
  mutations: {
    addItem(state, { item, quantity = 1 }) {
      const id = getItemId(item)
      const current = state.cart.items[id]
      if (current) {
        current.quantity += quantity
      } else {
        state.cart.items[id] = {
          ...item,
          quantity,
          uid: id
        }
      }
      state.cart = {
        ...state.cart
      }
      setCart()
    },

    removeItem(state, { item, quantity = 1 }) {
      const id = getItemId(item)
      const current = state.cart.items[id]
      if (current) {
        current.quantity -= quantity
      }
      if (current.quantity < 1) {
        delete state.cart.items[id]
      }
      state.cart = {
        ...state.cart
      }
      setCart()
    },

    setDate(state, date) {
      state.date = date
    },

    setOrderType(state, type) {
      state.orderType = type
    },

    setBed(state, bed) {
      state.bed = bed
    },

    clearCart(state) {
      state.cart.items = {}
      state.cart = {
        ...state.cart
      }
      setCart()
    },

    afterCheckout(state) {
      state.afterCheckout = true
    },

    resetAfterCheckout(state) {
      state.afterCheckout = false
    }
  },
  actions: {
    addItem({ commit }, { item, quantity = 1 }) {
      commit('addItem', {
        item,
        quantity
      })
    },
    removeItem({ commit }, { item, quantity = 1 }) {
      commit('removeItem', {
        item,
        quantity
      })
    },
    setDate({ commit }, date) {
      commit('setDate', date)
    },
    setOrderType({ commit }, type) {
      commit('setOrderType', type)
    },
    setBed({ commit }, bed) {
      commit('setBed', bed)
    },
    clearCart({ commit }) {
      commit('clearCart')
    },
    afterCheckout({ commit }) {
      commit('afterCheckout')
    },
    resetAfterCheckout({ commit }) {
      commit('resetAfterCheckout')
    }
  },

  getters: {
    cartItemQuantity: state => item => {
      const id = getItemId(item)
      return state.cart.items[id]?.quantity || 0
    },

    cartFoodTotalQuantity: state => foodId => {
      return Object.values(state.cart.items).reduce((sum, item) => {
        if (item.code == foodId) {
          return sum + item.quantity
        }
        return sum
      }, 0)
    },

    cartItems: state => {
      return values(state.cart.items)
    },

    cartCount: (state, getters) => {
      return sum(getters.cartItems.map(item => item.quantity))
    },

    cart: state => {
      return state.cart
    }
  }
})

export default store
